import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import PageTitle from '../components/Post/PageTitle'
import Container from '../components/Container'
import Layout from '../components/Layout'
import SEO from '../components/SEO'


const TitleContainer = styled.div`
  h1 {
    @media (max-width: 767px) {
      font-size: 3rem;
    }
  }
`

const ErrorText = styled.p`
  text-align: center;
  line-height: 1.6;
  font-family: ${props => props.theme.fonts.copy};
  font-weight: 400;
  font-style: italic;
  font-size: 1.5rem;

  a {
    color: ${props => props.theme.colors.text};
  }

  @media (max-width: 767px) {
    font-size: 1.25rem;
  }
`

const ErrorGif = styled(Img)`
  width: 750px;
  flex-shrink: 0;
  margin: 0 auto 2rem auto;
  
  @media (max-width: 767px) {
    width: 100%;
  }
`

const NotFoundPage = ({ data }) => (
  <Layout>
    <SEO title="404" description="Page Not Found" />
    <Container>
      <TitleContainer>
        <PageTitle>Woops, can't find that page</PageTitle>
      </TitleContainer>
      <ErrorGif fluid={data.contentfulAsset.fluid} />
      <ErrorText>
        Listen to Jocasta Nu - the page you're looking for does not exist. Check out our <Link to="/">home page</Link> or use the menu to navigate to a different page.
      </ErrorText>
    </Container>
  </Layout>
)

export default NotFoundPage


export const query = graphql`
  query {
    contentfulAsset(title: { eq: "404 Page Error" }) {
      id
      fluid(sizes: "(max-width: 500px)") {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      title
    }
  } 
`