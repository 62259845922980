import React from 'react'
import styled from '@emotion/styled'

const Title = styled.h1`
  font-size: 76px;
  text-transform: capitalize;
  font-weight: 600;
  text-align: center;
  margin: 0 0 3rem 0;
  margin: ${props => (props.small ? '1rem 0 4rem 0' : '0 0 3rem 0')};
  line-height: 1.2;
  font-family: ${props => props.theme.fonts.title};
  font-weight: 200;

  @media (max-width: 420px) {
    font-size: 4rem;
  }

  &.column-title {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    @media (max-width: 760px) {
      font-size: 3.625rem;
    }

    span {
      font-size: 2.25rem;
      color: ${props => props.theme.colors.highlight};
      margin-bottom: 0.625rem;

      @media (max-width: 760px) {
        font-size: 1.5rem;
      }
    }
  }

  span {
    font-family: ${props => props.theme.fonts.copy};
    font-weight: 400;
    font-style: italic;
    font-size: 2.875rem;
  }

  a {
    transition: all 0.2s;
    color: ${props => props.theme.colors.text};

    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
  }
  
  &.contact-page {
    margin-bottom: 2rem;

    @media (max-width: 800px) {
      margin-bottom: 0;
    }
  }
`

const PageTitle = props => {
  return <Title small={props.small} className={props.setClassName}>{props.children}</Title>
}

export default PageTitle
